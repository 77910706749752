import Login from '../components/Login'

const Home = () => {
	return (
		<>
			<Login />
			<section className="background">
			</section>
		</>
	)
}

export default Home