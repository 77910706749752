import { useEffect, useState } from 'react';
import { useLocation, NavLink, useParams, Link, useOutletContext } from "react-router-dom";
import useAuth from '../hooks/useAuth'
import Menu from '../components/Menu'
import { ToastContainer, toast } from 'react-toastify';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

const News = () => {

	const { auth, setAuth } = useAuth()
	const axiosPrivate = useAxiosPrivate();

	const [title, setTitle] = useState()
	const [content, setContent] = useState()

	const submitPost = async (e) => {
		e.preventDefault()

		const dismiss = () =>  toast.dismiss(postingNewsToast.current);
		const postingNewsToast = toast.loading('Posting News');
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.post('add-post',
			JSON.stringify({post_title: title, post_content: content}),
			{
				signal: controller.signal
			});

			// Update State

			// Update Toast Notification
			toast.update(postingNewsToast, { render: 'News Posted', type: 'success', isLoading: false, autoClose: 5000});
		} catch (err) {
			if (!err?.response) {
				toast.update(postingNewsToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else if (err.response?.status === 401) {
				toast.update(postingNewsToast, { render: 'Authorization Failed', type: 'error', isLoading: false});
				{/* setErrMsg('The email has already been taken.'); */}
			} else {
				toast.update(postingNewsToast, { render: 'Posting News Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	}

	return (
		<>
			<aside>
				<Menu />
			</aside>
			<section className="notifications">
				<h1>News</h1>
				<form>
					<label for="title">Title</label>
					<input
						type="text"
						id="title"
						name="title"
						value={title}
						onChange={(e) => setTitle(e.target.value)}
					/>
					<label for="content">Content</label>
					<textarea
						id="content"
						name="content"
						value={content}
						onChange={(e) => setContent(e.target.value)}
						>
					</textarea>
					<button onClick={submitPost}>Submit</button>
				</form>
			</section>
		</>
	)
}

export default News