import { useEffect, useState } from 'react';
import { useLocation, NavLink, useParams, Link, useOutletContext } from "react-router-dom";
import useAuth from '../hooks/useAuth'
import Menu from '../components/Menu'
import { MdDelete } from 'react-icons/md'
import { ToastContainer, toast } from 'react-toastify';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useMediaQuery } from 'react-responsive'

const Users = () => {

	const { auth, setAuth } = useAuth()
	const [users, setUsers] = useState(auth?.dashboard?.users)
	const axiosPrivate = useAxiosPrivate();

	const [deleteUserObj, setDeleteUserObj] = useState()
	const [openDelete, setOpenDelete] = useState(false)

	const isMobile = useMediaQuery({
		query: '(max-width: 700px)'
	})

	useEffect(() => {
		setUsers(auth?.dashboard?.users)
	}, [auth])

	const formatTimestamp = (ts) => {
		if (ts){
			const options = { month: "numeric", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric" }
			return new Date(ts).toLocaleDateString(undefined, options)
		} else {
			return "N/A"
		}
	}

	const handleCloseDelete = () => {
		setOpenDelete(false)
	}

	const deleteUser = (obj) => {
		setDeleteUserObj(obj)
		setOpenDelete(true)
	}

	const handleDeleteUser = async () => {
		const dismiss = () =>  toast.dismiss(deleteUserToast.current);
		const deleteUserToast = toast.loading('Deleting User');
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.delete('user/' + deleteUserObj.id,
			{
				signal: controller.signal
			});

			// Update local State
			setUsers(response.data.dashboard.users)
			setAuth(prevAuth => ({
				...prevAuth,
				dashboard: response.data.dashboard
			}))

			// Update Toast Notification
			toast.update(deleteUserToast, { render: 'User Deleted', type: 'success', isLoading: false, autoClose: 5000});
		} catch (err) {
			if (!err?.response) {
				toast.update(deleteUserToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else if (err.response?.status === 401) {
				toast.update(deleteUserToast, { render: 'Authorization Failed', type: 'error', isLoading: false});
				{/* setErrMsg('The email has already been taken.'); */}
			} else {
				toast.update(deleteUserToast, { render: 'User Delete Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	}

	return (
		<>
			<aside>
				<Menu />
			</aside>
			<section className="users">
				<h1>Users</h1>
				{users?.length ?
					<>
						<h3>{users.length} Users</h3>
						<ul className="list">
							<li className="keys">
								<div className="name">Type</div>
								<div className="role">Role</div>
								<div className="lastLoggedIn">Last Logged In</div>
								<div className="pass">Signed up from</div>
								<div className="buttons"></div>
							</li>
							{users.map((user, i) =>
								<li key={i}>
									<div className="name">{user.first_name} {user.last_name}</div>
									<div className={`role ${user.role === 8675309 ? 'superAdmin' : ''}${user.role === 1 ? 'user' : ''}`}><span>{user.role === 8675309 && 'Super Admin'}{user.role === 1 && 'User'}</span></div>
									<div className="lastLoggedIn">{formatTimestamp(user.last_logged_in)}</div>
									<div className="pass">{user.route}</div>
									<div className="buttons">
										{ user.role !== 8675309 ? <div className="delete" onClick={() => deleteUser(user)}><MdDelete /></div> : null }
									</div>
								</li>
							)}
						</ul>
					</>
				:
					<p>No users availble</p>
				}
			</section>

			<Dialog
				open={openDelete}
				onClose={handleCloseDelete}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				className="confirm"
			  >
				<DialogTitle id="alert-dialog-title">
				  {`Are you sure you want to delete user, "${deleteUserObj?.first_name} ${deleteUserObj?.last_name}"?`}
				</DialogTitle>
				<DialogContent>
					<>

					</>
				</DialogContent>
				<DialogActions>
					<button className="cancel" onClick={handleCloseDelete}>Cancel</button>
					<button className="confirm" onClick={handleDeleteUser}>Delete User</button>
				</DialogActions>
			</Dialog>

			<ToastContainer
			position="top-right"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
			theme="colored"
			/>

		</>

	)
}

export default Users