import { useState, useEffect, useRef } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import useAuth from '../hooks/useAuth'
import Menu from '../components/Menu'

const Overview = () => {

	const { auth, setAuth } = useAuth()
	const navigate = useNavigate()
	const dashboard = auth?.dashboard
	const status = auth?.dashboard?.status

	useEffect(() => {
		console.log(auth?.dashboard)
		// eslint-disable-next-line
	},[])

	const routeToPass = (e, i, name, cleanName) => {
		navigate(`/pass/${name}`, { state: { index: i }, replace: false})
	}

	return (
		<>
			<aside>
				<Menu />
			</aside>
			<section className="overview">
				<h1>Overview</h1>
				{status?.length
				? (
					<section className="passWrapper">
						{status.map((pass, i) =>
							<article className="pass" key={i} onClick={(e) => routeToPass(e, i, pass?.pass_name, pass?.pass_name_clean)} >
								<div className={`status ${pass?.open_override === null ?
										pass?.open ? 'open' : 'closed'
									:
										pass?.open_override ? 'open' : 'closed'
									}`
								}>
									<span></span>
								</div>
								<h3>{pass?.pass_name_clean}</h3>
							</article>
						)}
					</section>
				) : <p>No passes available</p>
				}
			</section>
		</>
	)
}

export default Overview