import { Routes, Route } from 'react-router-dom'
import Layout from './components/Layout'
import Home from './routes/Home'
import Overview from './routes/Overview'
import Pass from './routes/Pass'
import Error from './routes/Error'
import Notifications from './routes/Notifications'
import Maintenance from './routes/Maintenance'
import Reports from './routes/Reports'
import Users from './routes/Users'
import News from './routes/News'
import Popup from './routes/Popup'
import RequireAuth from './components/RequireAuth';
import Unauthorized from './routes/Unauthorized'
import PersistLogin from './components/PersistLogin'
import ForgotPassword from './routes/ForgotPassword';
import PasswordReset from './routes/PasswordReset';


function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>

        {/* Public Routes */}
        <Route path="/" element={<Home />} />
        <Route path="unauthorized" element={<Unauthorized />} />

        {/* Password Routes */}
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password" element={<PasswordReset />} />


        {/* Persist Login Routes */}
        <Route element={<PersistLogin />}>

            {/* Private Routes */}
            <Route element={<RequireAuth allowedRoles={[8675309]}/>}>
              <Route path="/overview" element={<Overview />} />
              <Route path="/notifications" element={<Notifications />} />
              <Route path="/maintenance" element={<Maintenance />} />
              <Route path="/users" element={<Users />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/news" element={<News />} />
              <Route path="/popup" element={<Popup />} />
              <Route path="/pass/:passname" element={<Pass />} />
            </Route>

        </Route>

        {/* 404 Error */}
        <Route path="/*" element={<Error />} />

      </Route>
    </Routes>
  );
}

export default App;
