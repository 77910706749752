import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAuth from "./useAuth";

const useLogout = () => {
	const { setAuth, setEditing } = useAuth();
	const axiosPrivate = useAxiosPrivate();

	const logout = async () => {
		setAuth({});
		try {
			const response = await axiosPrivate('logout', {
				withCredentials: true
			});
			setEditing(false);
		} catch (err) {
			console.error(err);
		}
	}
	return logout;
}

export default useLogout;