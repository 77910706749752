import { useNavigate } from 'react-router-dom'
import { useRef, useState, useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import Menu from '../components/Menu'

const Unauthorized = () => {
	const navigate = useNavigate();

	const { auth, setAuth } = useAuth();

	useEffect(() => {
		console.log(auth)
	}, [auth])

	const goBack = () => navigate(-1);

	return (
		<>
			<aside>
				<Menu />
			</aside>
			<section className="background">
				<h1>Unauthorized</h1>
				<button onClick={goBack}>Go Back</button>

			</section>
		</>

	)
}

export default Unauthorized