import { useEffect, useState } from 'react';
import { useLocation, NavLink, useParams, Link, useOutletContext } from "react-router-dom";
import useAuth from '../hooks/useAuth'
import Menu from '../components/Menu'
import { MdArrowCircleDown } from 'react-icons/md'
import { MdArrowCircleUp } from 'react-icons/md'
import { ToastContainer, toast } from 'react-toastify';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

const Maintenance = () => {

	const { auth, setAuth } = useAuth()
	const [maintenance, setMaintenance] = useState(auth?.dashboard?.maintenance)
	const axiosPrivate = useAxiosPrivate();

	const [editID, setEditID] = useState()
	const [editStatus, setEditStatus] = useState()

	useEffect(() => {
		setMaintenance(auth?.dashboard?.maintenance)
	}, [auth])

	const updateAllMM = async (status) => {
		const dismiss = () =>  toast.dismiss(enableMMToast.current);
		const enableMMToast = toast.loading(`${status ? 'Enabling' : 'Disabling'} Maintenance Modes`);
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.put('maintenance-mode',
			JSON.stringify({status: status}),
			{
				signal: controller.signal
			});

			// Update State
			setMaintenance(response.data.dashboard.maintenance)
			setAuth(prevAuth => ({
				...prevAuth,
				dashboard: response.data.dashboard
			}))

			// Update Toast Notification
			toast.update(enableMMToast, { render: `Maintenance Modes ${status ? 'Enabled' : 'Disabled'}`, type: 'success', isLoading: false, autoClose: 5000});
		} catch (err) {
			if (!err?.response) {
				toast.update(enableMMToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else if (err.response?.status === 401) {
				toast.update(enableMMToast, { render: 'Authorization Failed', type: 'error', isLoading: false});
				{/* setErrMsg('The email has already been taken.'); */}
			} else {
				toast.update(enableMMToast, { render: 'Maintenance Modes Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	}

	const updateMMID = async (id, status) => {
		const dismiss = () =>  toast.dismiss(enableMMToast.current);
		const enableMMToast = toast.loading(`${status ? 'Enabling' : 'Disabling'} Maintenance Mode`);
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.put('maintenance-mode/' + id,
			JSON.stringify({status: status}),
			{
				signal: controller.signal
			});

			// Update local State
			setMaintenance(response.data.dashboard.maintenance)
			setAuth(prevAuth => ({
				...prevAuth,
				dashboard: response.data.dashboard
			}))

			// Update Toast Notification
			toast.update(enableMMToast, { render: `Maintenance Mode ${status ? 'Enabled' : 'Disabled'}`, type: 'success', isLoading: false, autoClose: 5000});
		} catch (err) {
			if (!err?.response) {
				toast.update(enableMMToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else if (err.response?.status === 401) {
				toast.update(enableMMToast, { render: 'Authorization Failed', type: 'error', isLoading: false});
				{/* setErrMsg('The email has already been taken.'); */}
			} else {
				toast.update(enableMMToast, { render: 'Maintenance Mode Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	}

	const formatTimestamp = (ts) => {
		if (ts){
			const options = { month: "numeric", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric" }
			return new Date(ts).toLocaleDateString(undefined, options)
		} else {
			return "N/A"
		}
	}

	const handleUpdatePassMM = (e, i, id, status) => {
		console.log('updating mm on id:' + id + ' to ' + !status)
		setEditID(id)
		setEditStatus(!status)
	}

	const handleUpdateAllMM = (e, status) => {
		console.log('updating mm on all passes')
	}

	return (
		<>
			<aside>
				<Menu />
			</aside>
			<section className="maintenance">
				<h1>Maintenance</h1>

				<div className="globalButtons">
					<button onClick={() => updateAllMM(true)}>Enable All</button>
					<button onClick={() => updateAllMM(false)}>Disable All</button>
				</div>

				{maintenance?.length ?
					<ul className="list">
						<li className="keys">
							<div className="passName">Pass Name</div>
							<div className="enabled">Maintenance Mode</div>
							<div className="date">Last Updated</div>
							<div className="buttons"></div>
						</li>
						{maintenance?.map((mtn, i) =>
							<li key={i}>
								<div className="passName">{mtn.pass_name_clean}</div>
								<div className={`enabled ${mtn.maintenance_mode_enabled ? 'true' : 'false'}`}><span>{mtn.maintenance_mode_enabled ? 'Enabled' : 'Disabled'}</span></div>
								<div className="date">{formatTimestamp(mtn.updated_at)}</div>
								<div className="buttons">
									<div className={`maintenanceMode ${mtn.maintenance_mode_enabled ? '' : 'enable'}`} onClick={(e) => updateMMID(mtn.id, !mtn.maintenance_mode_enabled)}>{mtn.maintenance_mode_enabled ? <MdArrowCircleUp /> : <MdArrowCircleDown /> }</div>
								</div>
							</li>
						)}
					</ul>
				:
					<p>No reports available</p>
				}
			</section>

			<ToastContainer
			position="top-right"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
			theme="colored"
			/>

		</>
	)
}

export default Maintenance