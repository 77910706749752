import { useRef, useState, useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import Login from '../components/Login'

const Error = () => {

	const { auth, setAuth } = useAuth();

	useEffect(() => {
		console.log(auth)
	}, [auth])

	return (
		<>
			<aside>
				<Login />
			</aside>
			<section className="background">
				<h1>Error</h1>
			</section>
		</>

	)
}

export default Error