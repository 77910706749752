import { useRef, useState, useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import axios from '../api/axios';
const LOGIN_URL = 'console-login';

const Login = () => {

	const { auth, setAuth, persist, setPersist } = useAuth();
	const [pathname, setPathname] = useState('');

	const navigate = useNavigate();
	const location = useLocation();

	const from = pathname || "/overview";

	const emailRef = useRef();
	const errRef = useRef();

	const [email, setEmail] = useState('');
	const [pwd, setPwd] = useState('');
	const [errMsg, setErrMsg] = useState('');

	useEffect(() => {
		emailRef.current.focus();
		if ( location.state?.from?.pathname == "/"){
			setPathname("/overview")
		} else {
			setPathname(location.state?.from);
		}
	}, [])

	useEffect(() => {
		setErrMsg('');
	}, [email, pwd])

	useEffect(() => {
		localStorage.setItem('persist', persist);
	}, [persist])

	useEffect(() => {
		console.log(auth)
	}, [auth])

	const togglePersist = () => {
		setPersist(prev => !prev);
	}

	const handleSubmit = async (e) => {
		e.preventDefault();
		const dismiss = () =>  toast.dismiss(loginToast.current);
		const loginToast = toast.loading("Logging In");
		try {
			const response = await axios.post(LOGIN_URL,
				JSON.stringify({email: email, password: pwd, remember_me: persist}),
				{
					headers: {
						'Content-Type': 'application/json',
						'Accept': 'application/json'
					},
					withCredentials: true
				}
			);
			console.log(response?.data)
			const accessToken = response?.data?.token;
			const user = response?.data?.user;
			const roles = response?.data?.user?.role;
			const dashboard = response?.data?.dashboard
			setAuth({ user, roles, accessToken, dashboard });
			setEmail('');
			setPwd('');

			roles === 8675309 && navigate("/overview");

			// Setup Websocket connection
// 			let socket = new WebSocket("wss://api.iyh.app:6001/app/ABCDEF?protocol=7&client=js&version=4.3.1&flash=false");
//
// 			socket.onopen = function(e) {
// 			  alert("[open] Connection established");
// 			  alert("Sending to server");
// 			  socket.send("My name is John");
// 			};

			//wss://dnkaeqf52g.execute-api.us-west-2.amazonaws.com/production
			// const socket = new WebSocket('wss://dnkaeqf52g.execute-api.us-west-2.amazonaws.com/production')
			// socket.onopen = function(e){
			// 	console.log('websocket is connected')
			// 	socket.send(user?.id)
			// }

			// socket.addEventListener('open', e => {
			// 	console.log('websocket is connected')
			// 	console.log(e)
			// })

// 			socket.addEventListener('close', e => console.log('websocket is closed'))
// 			socket.addEventListener('error', e => console.log('websocket is in error', e))
// 			socket.addEventListener('message', e => {
// 				console.log('response', e?.data)
// 			})
//
// 			window.ask = function(msg) {
// 				const payload = {
// 					action: 'message',
// 					msg
// 				}
// 				socket.send(JSON.stringify(payload))
// 			}
			toast.update(loginToast, { render: 'Logged In', type: 'success', isLoading: false, autoClose: 5000});
		} catch (err) {
			if (!err?.response){
				toast.update(loginToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				setErrMsg('No Server Response');
			} else if ( err.response?.status === 400){
				toast.update(loginToast, { render: 'Missing username or password', type: 'error', isLoading: false, autoClose: 5000});
				setErrMsg('Missing username or password');
			} else if ( err.response?.status === 401){
				toast.update(loginToast, { render: 'Invalid Login', type: 'error', isLoading: false, autoClose: 5000});
				setErrMsg('Invalid Login');
			} else if ( err.response?.status === 403){
				toast.update(loginToast, { render: 'Login blocked by administrator', type: 'error', isLoading: false, autoClose: 5000});
				setErrMsg('Login blocked by administrator');
			} else {
				toast.update(loginToast, { render: 'Login Failed', type: 'error', isLoading: false, autoClose: 5000});
				setErrMsg('Login Failed')
			}
			{/* errRef.current.focus(); */}
		}
	}

	return (

		<aside>
				{/* <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p> */}
				<h1>Sign In</h1>
				<form onSubmit={handleSubmit}>
					<label htmlFor="email">Email:</label>
					<input
						type="email"
						id="email"
						ref={emailRef}
						autoComplete="off"
						onChange={(e) => setEmail(e.target.value)}
						value={email}
						required
					/>
					<label htmlFor="password">Password:</label>
					<input
						type="password"
						id="password"
						onChange={(e) => setPwd(e.target.value)}
						value={pwd}
						required
					/>
					<button>Sign In</button>

					<label htmlFor="persist">
					<input
						type="checkbox"
						id="persist"
						onChange={togglePersist}
						checked={persist}
					/>
					<span>Remember Me?</span>
					</label>
				</form>
				<p>
					<span className="forgotPassword">
						{/*put router link here */}
						<Link to="/forgot-password">Lost your password?</Link>
					</span>
				</p>
				<ToastContainer
					position="top-right"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					theme="colored"
				/>
		</aside>

	)
}

export default Login