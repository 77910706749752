import { useEffect, useState } from 'react';
import { useLocation, NavLink, useParams, Link, useOutletContext } from "react-router-dom";
import useAuth from '../hooks/useAuth'
import Menu from '../components/Menu'
import { MdDelete } from 'react-icons/md'
import { MdArchive } from 'react-icons/md'
import { ToastContainer, toast } from 'react-toastify';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const Pass = () => {

	const axiosPrivate = useAxiosPrivate();

	const { passname } = useParams();
	const location = useLocation();
	const { auth, setAuth } = useAuth()
	const dashboard = auth?.dashboard
	const status = auth?.dashboard?.status
	const reports = auth?.dashboard?.reports
	const notifications = auth?.dashboard?.notifications

	const [index, setIndex] = useState(location?.state?.index)

	const [archiveObj, setArchiveObj] = useState()
	const [deleteObj, setDeleteObj] = useState()

	const [openDelete, setOpenDelete] = useState(false)
	const [openArchive, setOpenArchive] = useState(false)

	const passDetails = status[index]

	const formatTimestamp = (ts) => {
		if (ts){
			const options = { month: "numeric", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric" }
			return new Date(ts).toLocaleDateString(undefined, options)
		} else {
			return "N/A"
		}
	}

	const archiveReport = (report) => {
		setArchiveObj(report)
		setOpenArchive(true)
	}

	const deleteReport = (report) => {
		setDeleteObj(report)
		setOpenDelete(true)
	}

	const handleDeleteReport = async () => {
		const dismiss = () =>  toast.dismiss(deleteUserToast.current);
		const deleteUserToast = toast.loading('Deleting Report');
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.delete('remove-report/' + deleteObj.id,
			{
				signal: controller.signal
			});

			// Update local State
			reports.filter(report => report.id !== deleteObj.id)

			// Update Toast Notification
			toast.update(deleteUserToast, { render: 'Report Deleted', type: 'success', isLoading: false, autoClose: 5000});
		} catch (err) {
			if (!err?.response) {
				toast.update(deleteUserToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else if (err.response?.status === 401) {
				toast.update(deleteUserToast, { render: 'Authorization Failed', type: 'error', isLoading: false});
				{/* setErrMsg('The email has already been taken.'); */}
			} else {
				toast.update(deleteUserToast, { render: 'Report Delete Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();

	}

	const handleArchiveReport = async () => {
		const dismiss = () =>  toast.dismiss(deleteUserToast.current);
		const deleteUserToast = toast.loading('Archiving Report');
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.get('archive-report/' + archiveObj.id,
			{
				signal: controller.signal
			});

			// Update local State
			reports.filter(report => report.id !== archiveObj.id)

			// Update Toast Notification
			toast.update(deleteUserToast, { render: 'Report Arhived', type: 'success', isLoading: false, autoClose: 5000});
		} catch (err) {
			if (!err?.response) {
				toast.update(deleteUserToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else if (err.response?.status === 401) {
				toast.update(deleteUserToast, { render: 'Authorization Failed', type: 'error', isLoading: false});
				{/* setErrMsg('The email has already been taken.'); */}
			} else {
				toast.update(deleteUserToast, { render: 'Report Archive Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();

	}

	const handleCloseDelete = () => {
		setOpenDelete(false)
	}

	const handleCloseArchive = () => {
		setOpenArchive(false)
	}

	return (
		<>
			<aside>
				<Menu />
			</aside>
			<section className="pass">
				<div className="passOverviewWrapper">
					<a href={`https://is${passDetails.pass_name}passopen.com`} target="_blank"><h1>{passDetails.pass_name_clean}</h1></a>
					<h3>Last Updated: {formatTimestamp(passDetails?.updated_at)}</h3>
						<div className="passOverviewContainer">
							<div className="status"><strong>Status:</strong> {passDetails?.open ? 'Open' : 'Closed' }</div>
							<div className="statusOverride"><strong>Status Override:</strong> {passDetails?.open_override === null ? 'Inactive'
							: passDetails?.open_override ? 'Open': 'Closed' }</div>
							<div className="chainLaw"><strong>Chain Law:</strong> {passDetails?.chain_law ? 'Active' : 'No' }</div>
							<div className="tractionLaw"><strong>Traction Law:</strong> {passDetails?.traction_law ? 'Active' : 'No' }</div>
						</div>
				</div>
				<div className="reports">
					<h4>Reports</h4>
					{reports?.length ?
						<ul className="list">
							<li className="keys">
								<div className="type">Type</div>
								<div className="category">Category</div>
								<div className="report">Report</div>
								<div className="confirmed">Confirmed</div>
								<div className="opposed">Opposed</div>
								<div className="date">Date</div>
								<div className="buttons"></div>
							</li>
							{reports?.filter(report => report.pass_name === passname).map((report, i) =>
								<li key={i}>
									<div className="type">{report.report_type}</div>
									<div className="category">{report.report_category}</div>
									<div className="report">{report.report}</div>
									<div className="confirmed">{report.confirmed}</div>
									<div className="opposed">{report.opposed}</div>
									<div className="date">{formatTimestamp(report.updated_at)}</div>
									<div className="buttons">
										<div onClick={(e) => archiveReport(report)} className="archive"><MdArchive /></div>
										<div onClick={(e) => deleteReport(report)} className="delete"><MdDelete /></div>
									</div>
								</li>
							)}
						</ul>
					:
						<p>No reports available</p>
					}
				</div>
			</section>

			<Dialog
				open={openDelete}
				onClose={handleCloseDelete}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				className="confirm"
			  >
				<DialogTitle id="alert-dialog-title">
				  {`Are you sure you want to delete report, "${deleteObj?.report}"?`}
				</DialogTitle>
				<DialogContent>
					<>
						<p><strong>Reported at:</strong> {formatTimestamp(deleteObj?.updated_at)}</p>
					</>
				</DialogContent>
				<DialogActions>
					<button className="cancel" onClick={handleCloseDelete}>Cancel</button>
					<button className="confirm" onClick={handleDeleteReport}>Delete Report</button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={openArchive}
				onClose={handleCloseArchive}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				className="confirm"
			  >
				<DialogTitle id="alert-dialog-title">
				  {`Are you sure you want to archive report, "${archiveObj?.report}"?`}
				</DialogTitle>
				<DialogContent>
					<>
						<p><strong>Reported at:</strong> {formatTimestamp(archiveObj?.updated_at)}</p>
					</>
				</DialogContent>
				<DialogActions>
					<button className="cancel" onClick={handleCloseArchive}>Cancel</button>
					<button className="confirm" onClick={handleArchiveReport}>Archive Report</button>
				</DialogActions>
			</Dialog>

		</>
	)
}

export default Pass