import { Outlet } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useState, useEffect } from 'react';
import axios from 'axios'

const Layout = () => {

	const [loading, setLoading] = useState(false)

	return (
		<main className="App">
			<Header />
			<Outlet />
			<Footer />
		</main>
	)
}

export default Layout