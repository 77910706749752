import { useEffect, useState } from 'react';
import { useLocation, NavLink, useParams, Link, useOutletContext } from "react-router-dom";
import useAuth from '../hooks/useAuth'
import Menu from '../components/Menu'

const Notifications = () => {

	const { auth, setAuth } = useAuth()
	const notifications = auth?.dashboard?.notifications

	const addNotification = () => {

	}

	return (
		<>
			<aside>
				<Menu />
			</aside>
			<section className="notifications">
				<h1>Notifications</h1>
				<form action={addNotification}>

				</form>
			</section>
		</>

	)
}

export default Notifications